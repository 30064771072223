@import "../variables";

.sm-page-widget-feed .sm-feed {
    max-width: 100%;
}

.sm-page-widget-feed .sm-feed-item {
    margin: 0 0 72px;
    overflow: hidden;
}

.sm-page-widget-feed .sm-feed-item .sm-feed-item-title {
    display: inline-block;
}

.sm-page-widget-feed .sm-feed-item .sm-feed-item-description {
    margin-top: 8px;
}

.sm-page-widget-feed .sm-feed-item img {
    max-width: 100%;
}

@media screen and (max-width: @small-max-width) {
    .sm-page-widget-feed .sm-feed-item .sm-feed-item-date {
        -webkit-text-size-adjust: none;
    }

    .sm-page-widget-feed .sm-feed-item .sm-feed-item-description {
        font-size: 16px;
        -webkit-text-size-adjust: none;
    }
}
